import config from 'isomorphic-config'
import analytics from '@/shared-utils/analytics'
import { GQL_QUERY_ERROR, CATCH_BLOCK_ERROR } from '@/constants/errorMessages'
import {
  fetchAndHandleErrors,
  parseCatchBlockErrors
} from '@/shared-utils/splunk-logging-helper'
import type { GraphResponseData } from '@/types'
import type { DealDataResponse } from './types'
import { jsonContentTypeHeader } from '../../server/constants'
import { hasNumberProp, hasStringProp } from '../../utils/typeguard-helpers'

type InputType = {
  latitude: number
  longitude: number
  appName: string
  appVersion: string
}

type GetBundleDealsResponse = {
  getBundleDeals: {
    bundles: DealDataResponse[]
  }
}
const PKG_DEALS_QUERY = `
  query getBundleDeals($input: BundleDealsInput) {
    getBundleDeals(input: $input) {
      bundles {
        cityName
        cityImageUrl
        priceAmount
        retailPriceAmount
        searchLink
        productName
        description
      }
    }
  }
`

function isDealDataResponseType(obj: unknown): obj is DealDataResponse {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }
  return (
    hasStringProp(obj, 'cityName') &&
    hasStringProp(obj, 'cityImageUrl') &&
    hasStringProp(obj, 'productName') &&
    hasStringProp(obj, 'description') &&
    hasStringProp(obj, 'searchLink') &&
    hasNumberProp(obj, 'priceAmount') &&
    hasNumberProp(obj, 'retailPriceAmount')
  )
}

function isDealDataResponseTypeArray(
  array: unknown[]
): array is DealDataResponse[] {
  return array.every(isDealDataResponseType)
}

function isValidGraphQLResponseAndData(
  payload: unknown
): payload is GraphResponseData<GetBundleDealsResponse> {
  if (payload && payload instanceof Object) {
    if ('data' in payload && payload.data && typeof payload.data === 'object') {
      if ('getBundleDeals' in payload.data) {
        return true
      }
    }
  }
  return false
}
const OPERATION_NAME = 'getBundleDeals'

const truncateLocation = (value: number) => Number(value.toFixed(3))
const graphConfig = 'stellate-graph-cdn'

const serviceName = 'getBundleDeals'

export default async function getBundleDeals({
  latitude,
  longitude,
  appName,
  appVersion
}: InputType) {
  const { url, timeout } = config.client[graphConfig]
  const requestUrl = `${url}?gqlOp=${OPERATION_NAME}`
  const variables = {
    input: {
      latitude: truncateLocation(latitude),
      longitude: truncateLocation(longitude)
    }
  }
  const options = {
    signal: AbortSignal.timeout(timeout),
    method: 'POST',
    headers: {
      ...jsonContentTypeHeader,
      'apollographql-client-name': appName,
      'apollographql-client-version': appVersion
    },
    body: JSON.stringify({
      query: PKG_DEALS_QUERY,
      variables,
      operationName: OPERATION_NAME
    })
  }
  const requestData = {
    requestUrl: graphConfig,
    requestBody: JSON.stringify({
      variables
    }),
    requestMethod: 'POST'
  }

  try {
    const payload = await fetchAndHandleErrors({
      requestUrl,
      options
    })

    if (payload.responseErrorData) {
      analytics.logError({
        serviceName,
        message: payload.message,
        ...payload.responseErrorData,
        ...requestData
      })
      return []
    }
    if (isValidGraphQLResponseAndData(payload)) {
      const { bundles } = payload.data.getBundleDeals
      if (isDealDataResponseTypeArray(bundles)) {
        return bundles
      }
    }
    if (payload.data.errors) {
      const { errors } = payload.data
      analytics.logError({
        error: JSON.stringify(errors),
        message: GQL_QUERY_ERROR,
        serviceName,
        ...requestData
      })
      return []
    }
    return []
  } catch (error) {
    analytics.logError({
      error: parseCatchBlockErrors(error),
      message: CATCH_BLOCK_ERROR,
      serviceName,
      ...requestData
    })
    return []
  }
}
