import { useEffect, useState, useRef } from 'react'
import analytics from '@/shared-utils/analytics'
import useBootstrapData from '@/hooks/useBootstrapData'
import getBundleDeals from './get-bundle-deals'
import type { DealDataResponse } from './types'

const useFetchPackages = ({
  latitude,
  longitude
}: {
  latitude: number | null
  longitude: number | null
}) => {
  const { appName, appVersion } = useBootstrapData()
  const [deals, setDeals] = useState<DealDataResponse[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const dataFetchedRef = useRef(false)

  useEffect(() => {
    const fetchPackageDeals = async () => {
      try {
        if (!latitude || !longitude || isLoading || dataFetchedRef.current) {
          return
        }
        dataFetchedRef.current = true
        setIsLoading(true)
        const bundleDeals = await getBundleDeals({
          latitude,
          longitude,
          appName,
          appVersion
        })
        setDeals(bundleDeals)
      } catch (error) {
        const catchErrorMessage = JSON.stringify(error)
        analytics.logError({
          message: `Error in useFetchPackages: ${catchErrorMessage}`,
          appVersion,
          appName
        })
      } finally {
        setIsLoading(false)
      }
    }
    void fetchPackageDeals()
  }, [latitude, longitude, isLoading, appName, appVersion])

  return { deals, isLoading }
}

export default useFetchPackages
