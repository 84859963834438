import type { DealDataResponse } from './types'

type BuildGA4ObjectForPackageDealTilesInput = {
  event: 'select_promotion'
  listings: DealDataResponse[]
  selectedItemIndex?: number
}

export default function buildGA4ObjectForPackageDealTiles({
  event,
  listings,
  selectedItemIndex = -1
}: BuildGA4ObjectForPackageDealTilesInput) {
  return {
    event,
    attributes: {
      type: 'package',
      page_name: 'homepage',
      product_name: 'bundle',
      promotion_id: 'deal_tile',
      promotion_name: 'hotel_air',
      location_id: 'carousel',
      ecommerce: {
        items: listings
          .filter(
            (_, index) => selectedItemIndex < 0 || index === selectedItemIndex
          )
          .map((listing, index) => ({
            item_id: `deal-${index}`,
            item_name: listing.cityName,
            price: listing.priceAmount,
            promotion_id: 'deal_tile',
            promotion_name: `hotel_air`
          }))
      }
    }
  } as const
}
